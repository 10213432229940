import { SupportedLangs } from '../../i18n';
import { isServer } from '../../utils';
import { Game } from '../models/Game';
import { EventCategoryService } from '../services/EventCategoryService';

export const generateSeoHreflangLinksList = (
  absoluteBaseHref: string,
  arenaMainLang = 'en',
  subPathArr: string[] = [],
  games?: Game[],
  fullCategories?: string[]
) => {
  const dict = myDict();
  const checkDict = (locale: string, key: string) => {
    const mappedLocale = locale ? 'en' : locale;

    return (
      dict.hasOwnProperty(key) && dict[key].hasOwnProperty(mappedLocale) // simplest case
        ? dict[key][mappedLocale]
        : subPathArr[0] === 'ROUTES.CATEGORY' && subPathArr.length >= 2 // for categories
          ? getCategoryName(subPathArr[1], mappedLocale)
          : key // for other cases (e.g. game name)
    );
  };
  const getSubPath = (locale: string) => '/' + subPathArr.map(pathPart => checkDict(locale, pathPart)).join('/');
  const origin = isServer ? absoluteBaseHref : window.location.origin;
  const pathname = isServer ? null : window.location.pathname;
  const localePattern = new RegExp(`/(${SupportedLangs.join('|')})(/|$)`);
  // In case if we have link http://localhost:4200/es, and we need to apply another language in alternate links
  // it helps to avoid links like http://localhost:4200/es/de
  const cleanOrigin = origin.replace(localePattern, '');
  const currentPageHasLocale = pathname
    ? SupportedLangs
      .filter(
        locale => Boolean(pathname.match(new RegExp(`^\/(${locale}){1}`, 'gi')))
      )
      .reverse() // for 'en' and 'en-[sub locale]'
      [0]
    : null;
  const currentPageLocale = Boolean(currentPageHasLocale) ? currentPageHasLocale : '';
  const locales = [...SupportedLangs];
  const createAlternateLink = (locale: string) => {
    const href = locale === arenaMainLang
      ? `${cleanOrigin}${getSubPath(locale)}`
      : `${cleanOrigin}/${locale}${getSubPath(locale)}`.replace(/\/$/, '');

    return {
      rel: 'alternate',
      hreflang: locale,
      href
    };
  };

  return (locales
      .filter(locale => currentPageHasLocale ? locale !== currentPageLocale : locale !== arenaMainLang)
      .map(locale => {
        const hasGameInCategory = games
          ?.some(g => g.sitemapInfo
            ?.some(s => s.tags
              ?.some(t => t.locale === locale)));

        if (hasGameInCategory || subPathArr.includes('ROUTES.ALL_GAMES')) {
          return createAlternateLink(locale);
        }

        return createAlternateLink(locale);
      })
      .concat([
        createAlternateLink(arenaMainLang)
      ])
      .filter(Boolean)
  );

  function getCategoryName(categoryFromUrl: string, locale: string) {
    const categoryDict = myDict().CATEGORIES;
    const eventCatNamesList = EventCategoryService.getEventCatNamesList(fullCategories);
    const hasCategoryName = (category: string, nameCategory = categoryFromUrl) =>
      Object?.values(categoryDict[category]).includes(nameCategory);
    const hasSeasonalCategory = (nameCategory = categoryFromUrl) => {
      return eventCatNamesList.includes(nameCategory.replace(/[^a-zA-Z0-9\s!]+/g, ' ').replace(/\s+/g, ' ').trim());
    };
    let categoryName = 'word';
    let isSeasonalCategory = false;

    switch (true) {
      case (hasCategoryName('word')):
        categoryName = 'word';
        break;
      case (hasCategoryName('daily')):
        categoryName = 'daily';
        break;
      case (hasCategoryName('card')):
        categoryName = 'card';
        break;
      case (hasCategoryName('puzzles')):
        categoryName = 'puzzles';
        break;
      case (hasCategoryName('casino')):
        categoryName = 'casino';
        break;
      case (hasCategoryName('arcade')):
        categoryName = 'arcade';
        break;
      case (hasSeasonalCategory(categoryFromUrl)):
        categoryName = categoryFromUrl;
        isSeasonalCategory = true;
        break;
      default:
        break;
    }

    if (isSeasonalCategory) {
      return categoryName;
    }

    return categoryDict[categoryName][locale];
  }
};

/* ToDO: Think how to replace hardcoded dict with actual data from lang jsons without fetching the whole batch */

// Yet did it like in arenax-api-admin done for sitemap.xml generator
export function myDict() {
  return ({
    'ROUTES.ALL_GAMES': {
      en: 'all-games',
      de: 'alle-spiele',
      fr: 'tous-les-jeux',
      es: 'todos-los-juegos',
      it: 'tutti-i-giochi'
    },
    'ROUTES.CATEGORY': {
      en: 'category',
      de: 'kategorie',
      fr: 'catégorie',
      es: 'categoría',
      it: 'categoria'
    },
    'ROUTES.GAMES': {
      en: 'games',
      de: 'spiele',
      fr: 'jeux',
      es: 'juegos',
      it: 'giochi'
    },
    'ROUTES.HELP': {
      en: 'help',
      de: 'hilfe',
      fr: 'assistance',
      es: 'ayuda',
      it: 'aiutare'
    },
    'ROUTES.PROFILE': {
      en: 'profile',
      de: 'profil',
      fr: 'profil',
      es: 'perfil',
      it: 'profilo'
    },
    'CATEGORIES': {
      word: {
        en: 'word',
        de: 'wort',
        fr: 'mot',
        es: 'palabras',
        it: 'parole'
      },
      daily: {
        en: 'daily',
        de: 'täglich',
        fr: 'quotidien',
        es: 'diario',
        it: 'giornaliero'
      },
      card: {
        en: 'card',
        de: 'karte',
        fr: 'carte',
        es: 'cartas',
        it: 'carte'
      },
      puzzles: {
        en: 'puzzles',
        de: 'puzzlen',
        fr: 'casse-tête',
        es: 'rompecabezas',
        it: 'rompicapo'
      },
      casino: {
        en: 'casino',
        de: 'casino',
        fr: 'casino',
        es: 'casino',
        it: 'casino'
      },
      arcade: {
        en: 'arcade',
        de: 'arkade',
        fr: 'arcade',
        es: 'arcade',
        it: 'arcade'
      },
      strategy: {
        en: 'strategy',
        de: 'strategie',
        fr: 'stratégie',
        es: 'estrategia',
        it: 'strategia'
      }
    }
  });
}
